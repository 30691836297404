import * as React from 'react';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';

function ConnectFooter() {
    return (
        <Container maxWidth="sm">
            <Typography variant="body1">Lets Connect!</Typography>
            <IconButton href={'https://github.com/kikijee'}><GitHubIcon sx={{ fontSize: 35 }} /></IconButton>
            <IconButton href={'https://www.linkedin.com/in/christian-manibusan-885005259/'}><LinkedInIcon sx={{ fontSize: 35 }} /></IconButton>
            <IconButton href={'https://www.instagram.com/christian_manibusan/?hl=en'}><InstagramIcon sx={{ fontSize: 35 }} /></IconButton>
        </Container>
    );
}

const projects = [
    {
        title: "PELE",
        description: [
            `PELE aka the Protégé Effect Learning Entity is an application to assist in learning by invoking the Protégé Effect.
            The Protégé Effect is a learning concept that suggest that teaching a specific subject matter validates knowledge 
            and induces effective learning. This is a also known as "learning by teaching". By explaining and teaching a concept 
            to somebody, the teacher can develop their own understanding of the subject while identifying gaps in their own knowledge. 
            In 2016, there was a study that found that individuals that learn with the preparation to teach show a more complete knowledge 
            of the material they are learning.`,
            `With PELE, the goal is to provide a framework and application for somebody to teach a subject without needing a second individual. 
            This is accomplished through PELE recording the subject matter that you are verifying your knowledge with and providing a plethora 
            of questions created to better establish your understanding. With these questions, you can save them as well as tag various other 
            denotations, like completion.`
        ],
        responsibilities: [
            `• Crafted the desgin and functionality of PELE frontend components, including but not limited to, Sign Up, Sign In, User Dashboard 
            and its children components, and Recording pages`,
            `• Implemented Recording functionality through the usage of the MediaRecorder API`,
            `• Designed database entities and their relationships with the use of SQLAlchemy and Pydantic`,
            `• Enabled database migrations functionality with Alembic`,
            `• Created REST API endpoints for User, Study Set, and Question entities. Used SQLAlchemy ORM and Pydantic for buiness logic of endpoints
            with request validation and sanitation in mind`,
            `• Implemented security through JWT's, browser cookies, and both CORS and custom middleware to secure both backend and frontend components
            only allowing access to verified users to secured endpoints`,
            `• Deployed application to two different production servers for testing and initial release.`,
            `• Server 1 (testing): Raspberry Pi utilizing Ubuntu Server, Docker, Docker-Compose, Systemctl, and Cloudflare Tunnels for reverse proxy and
            handling of SSL certificate`,
            `• Server 2 (initial release): EC2 utilizing Ubuntu Server, Docker, Docker-Compose, Systemctl, and Caddy for reverse proxy and handling
            of SSL certificate`
        ],
        tech: ['Python', 'JavaScript', 'Node.JS', 'FastAPI', 'React.JS', 'MUI', 'PostgreSQL', 'Docker', 'Docker Compose', 'OpenAI API', 'Cloudflare Tunnel', 'EC2'],
        coverPicture: 'pele/pele-cover.png',
        links: ['https://github.com/letriandrew/PELE', 'https://pele.lol/'],
        pictures: ['pele/Screenshot 2024-10-13 161129.png', 'pele/Screenshot 2024-10-13 161351.png', 'pele/Screenshot 2024-10-13 161521.png', 'pele/Screenshot 2024-10-13 161625.png','pele/Screenshot 2024-10-17 012412.png','pele/Screenshot 2024-10-28 001241.png','pele/Screenshot 2024-10-28 001309.png']
    },
    {
        title: "Blockchain Academic Records",
        description: [
            `In today's digital age, the manner in which we store, retrieve, and authenticate records has experienced a radical 
            transformation. The need for a secure, transparent, and decentralized system for managing student records has never 
            been more evident. Our “Blockchain Education Records” aims at reimaging how these records are maintained and accessed.`,
            `At its core, this solution seeks to leverage the power of blockchain technology and the InterPlanetary File System (IPFS) 
            to create a prototype for an educational records management system. The solution aims to be secure, transparent, and 
            tamper-proof. The use of Ethereum's testnet provides us with a realistic yet safe environment to deploy and test our solution, 
            while an IPFS node, hosted on an AWS Ubuntu instance, ensures the data remains accessible and persistent.`,
            `The application hybrid model of blockchain for educational records seamlessly integrates a clean client-side user interface with a 
            blockchain transaction-viewing interface, offering a user-friendly experience for requesting, uploading, or searching 
            documents within our application. The incorporation of blockchain technology enhances transparency and verifiability through 
            integrated transaction tracking and exploration features. To ensure low transaction costs, resource-intensive computations are 
            handled client-side, outside of our smart contract. Our system prioritizes security, implementing robust measures to prevent 
            malicious users from uploading illegitimate documents or tampering with existing documents on the network. Example security features 
            include a dual-party agreement requirement for requesting and approving actions. For instance, to register as an institution account, 
            the user seeking approval must be approved from an application administrator. Additionally, our system ensures that third parties 
            seeking student records must possess specific information, such as the student's first and last name, date of birth, and the unique 
            content identifier hash associated with the record being searched. Furthermore, our modular approach of implementing technologies that 
            are highly compatible with many well known platforms and frameworks ensures high scalability, positioning our system to adapt and grow 
            effectively with new technologies. We believe this application is a model solution to the pitfalls of traditional record keeping services 
            used by institutions around the world. By utilizing the security, transparency, and cost-effectiveness of the decentralized web via 
            blockchain networks, our application can be hosted by any institution that wants a seamless and price-conscious solution for educational records.
`
        ],
        responsibilities: [
            `• Collaborated with a team of developers to conceptualize, design, and implement a comprehensive academic record storage and 
            verification solution encompassing both frontend and backend components. Employed agile methodologies to adapt to evolving project 
            requirements, ensuring flexibility and responsiveness throughout the software development life cycle.`,
            `• Conducted regular team meetings and meetings with stakeholders to gather customer needs, discuss product features, 
            and assign tasks, all while keeping open communication and maintaining alignment with project objectives.`,
            `• Contributed 5000+ lines of code and took on lead roles in the backend development and smart contract creation and integration.`,
            `• Implemented Auth functionalities through the use of JWT's, browser cookies, and custom middleware that secured server-side endpoints and frontend routes.`,
            `• Created REST API endpoints for Record/Transcript and User entities`,
            `• Implemented frontend design and functionalities for the sign up, sign in, user dashboard, and institution pages`,
            `• Led the smart contract design and integration into the application, along with setting up resuable funtionality with the Ethers API to allow
            Meta Mask wallet connection and interaction with the smart contract`,
            `• Created comprehensive unit tests for all existing server-side endpoints`
        ],
        tech: ['Solidity', 'JavaScript', 'Node.JS', 'Express.JS', 'React.JS', 'MUI', 'Hardhat.JS', 'IPFS', 'PostgreSQL', 'Apache', 'EC2', 'Super Test', 'Jest'],
        coverPicture: 'blockchain/blockchain-cover.png',
        links: [
            'https://github.com/kikijee/Blockchain-Academic-Records'
        ],
        pictures: ['blockchain/Screenshot 2024-10-17 013145.png','blockchain/Screenshot 2024-10-17 013337.png','blockchain/Screenshot 2024-10-17 013543.png','blockchain/Screenshot 2024-10-17 012923.png','blockchain/Screenshot 2024-10-17 012623.png','blockchain/Screenshot 2024-10-17 012649.png','blockchain/Screenshot 2024-10-17 012708.png','blockchain/Screenshot 2024-10-17 012731.png']
    },
    {
        title: "Gettr",
        description: [
            `Gettr is a forum web application where users can interact and communicate with one another on projects and ideas they may have. 
            What makes Gettr different from other web application forums is that it is specifically tailored towards California State University 
            San Marcos (CSUSM) students and faculty.`,
            `Gettr was created by Eric Paulin, Christian Manibusan, Angel Samora, and Ethan E or better known as our group name, “The Elite Four”. 
            We are four computer science students at CSUSM who strive to make quality software and felt Gettr was important as it tries to bridge 
            the communication gap many students on campus face of not knowing where they can find others, especially when trying to get projects 
            done. Our team has seen this firsthand with many engineering students who have tons of wonderful ideas but do not know where to start. 
            We hope Gettr can be that first step in helping students connect and create together.`
        ],
        responsibilities: [
            `• Designed and implemented a RESTful API to handle HTTP requests using Spring Data JPA and Spring Web for User, Posts, Comments, and Messages entities`,
            `• Desgined and implemented entity relationships through the use of JPA and Hibernate (Spring Data)`,
            `• Incorporated STOMP protocol for direct messaging with Spring Web Socket and stomp.js, while ensuring security through the integration of JWT token functionality 
            with Spring Security.`,
            `• Designed and crafted functionalites for User Dash and Direct Messages frontend components`
        ],
        tech: ['Java', 'JavaScript', 'Spring', 'JPA', 'Hibernate', 'React.JS', 'MUI', 'MySQL'],
        coverPicture: 'gettr/gettr-cover.png',
        links: ['https://github.com/Peggy-works/GettrProject'],
        pictures: ['gettr/Screenshot 2024-10-17 014627.png','gettr/Screenshot 2024-10-17 014714.png','gettr/Screenshot 2024-10-17 014942.png','gettr/Screenshot 2024-10-17 015038.png','gettr/Screenshot 2024-10-17 015223.png']
    },
    {
        title: "Financial Advisor Bot",
        description: [
            `Designed a Financial Advisor application for the LPL Financial Hackathon with a team of developers. Functionalities  include 
            fetching current stock prices from Yahoo Finance with an API, tracking simulated portfolios through buying and  selling options, 
            portfolio calculations (gains/total worth) `,
            `Implemented two trained machine learning models for a responsive chatbot that can navigate the application for the user and a 
            stock price prediction for the next closing day based on a given company ticker. 
`
        ],
        responsibilities: [
            `• Designed and implemented chat bot model with the Neuralintents library and trained the model to respond to certain user prompts based off of our application's core functionalities`,
            `• Designed user interface with Tkinter making a simple yet effective UI for the user to navigate`
        ],
        tech: ['Python', 'Tensorflow', 'Neuralintents', 'Pandas', 'Matplotlib', 'CustomTkinter'],
        coverPicture: 'finbot/finbot-cover.png',
        links: ['https://github.com/kikijee/FinancialAdvisorBot'],
        pictures: []
    },
    {
        title: "Sorting Algorithm Visuals",
        description: [
            `This program is made for learning purposes on 6 sorting algorithms through dynamic visuals on a fully functioning GUI. 
            Functionalities of this application include: Ability to generate a random array of integers through the options of array size(integer), 
            lower bound(integer), upper bound(integer). Functioning pause, play, foward, and backward during execution.
            Execution speed slider that is functional before and during execution for dynamic speed changes.
            Algorithm drop down menu can choose from the options: Insertion Sort, Selection Sort, Bubble Sort, Quick Sort, Merge Sort, and Heap Sort.
            Comparison counter that allows user to see the amount of sorting comparisons during its runtime`
        ],
        responsibilities: [
            `• Built out UI with Tkinter with ease of use and consistancy being priority in its design and functionality`,
            `• Designed and implemented sorting algorithms to work with the animation functionality of the application`,
            `• Designed animation effects to optimize performance without hindering the main thread allowing room for the implementation of playback features`,
        ],
        tech: ['Python', 'Tkinter'],
        coverPicture: 'sorting/sorting-cover.png',
        links: ['https://github.com/kikijee/SortingAlgorithmVisuals'],
        pictures: ['sorting/Screenshot 2024-10-17 003254.png','sorting/Screenshot 2024-10-17 003335.png','sorting/Screenshot 2024-10-17 003442.png']
    },
    {
        title: "Lexical Analysis GUI",
        description: [
            `This program is made to simulate the lexical analysis and parsing of a compiler process and to visualize the process through a GUI with use of the Tkinter and Regex libraries in python.
            Designed to accept three different types of expressions of BNF Grammer.`
        ],
        responsibilities: [
            `• Designed simple but effective UI with Tkinter`,
            `• Implemented UI functionality including retrieving user input and tokenizing`,
            `• Parsing tokens while checking against BNF grammer rules through the recursive decent algorithm`,
            `• Generating and outputting parse tree on the UI`,
        ],
        tech: ['Python', 'Tkinter', 'Regex'],
        coverPicture: 'lexical/lexical-cover.png',
        links: ['https://github.com/kikijee/Lexical-Analysis-and-Parsing-GUI'],
        pictures: []
    },
]

export default function Projects() {

    const [currProject, setCurrProject] = useState(null)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (i) => {
        setCurrProject(i);
        setOpen(true);
    };

    const handleClose = () => {
        setCurrProject(null);
        setOpen(false);
    };



    // useEffect(()=>{
    //     setTimeout(() => {
    //         setTrans(true);    
    //     }, 500);
    // })

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'flex-start', // Align all content to the left
                    backgroundImage: 'url("/01J567SWV2E8EYCP4ARBV5Z1MN-low-res-branded-.JPEG")', // Set the background image
                    backgroundSize: 'cover',  // Cover the entire area
                    backgroundRepeat: 'no-repeat', // Prevent repeating
                    backgroundAttachment: 'scroll', // Create the parallax scrolling effect
                    backgroundPosition: 'top-center',
                }}
            >
                <CssBaseline />

                <Container
                    sx={{
                        mt: {
                            xl: 25,
                            lg: 20,
                            md: 20,
                            sm: 20,
                            xs: 20
                        },
                        mb: 10
                    }}
                >
                    <Grid
                        container
                        spacing={{ xl: 8, lg: 9, md: 10, sm: 11, xs: 12 }}
                        justifyContent="center"
                        sx={{
                            //backgroundColor:'rgba(18, 18, 18, 1)',
                            //padding: 4,
                            borderRadius: 10,
                        }}
                    >
                        {projects.map((data, index) => (
                            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} display="flex" justifyContent="center">
                                <Grow
                                    in={true}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(true ? { timeout: 1000 + (index * 1000) } : {})}
                                >
                                    <Card
                                        sx={{
                                            position: "relative",
                                            overflow: "hidden",
                                            borderRadius: 3,
                                            border: 2,
                                            borderColor: "#000000",
                                            "&:hover .image": {
                                                filter: "blur(5px)",
                                                transform: "scale(1.1)",
                                                cursor: 'pointer'
                                            },
                                            "&:hover .title": {
                                                opacity: 1,
                                                transform: "translate(-50%, -50%) scale(1)",
                                                cursor: 'pointer'
                                            },
                                            width: {
                                                xl: 400,
                                                lg: 350,
                                                md: 300,
                                                sm: 275,
                                                xs: 320
                                            },
                                            height: {
                                                xl: 400,
                                                lg: 350,
                                                md: 300,
                                                sm: 275,
                                                xs: 320
                                            },
                                        }}
                                        onClick={() => {
                                            handleClickOpen(index);
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            image={data.coverPicture}
                                            alt={data.title}
                                            className="image"
                                            sx={{
                                                transition: "filter 0.3s ease, transform 0.3s ease",
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                        <Typography
                                            variant="h5"
                                            className="title"
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%) scale(0.9)",
                                                transition: "opacity 0.3s ease, transform 0.3s ease",
                                                opacity: 0,
                                                color: "#fff",
                                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                padding: "10px 20px",
                                                borderRadius: "5px",
                                                textAlign: "center",
                                                fontFamily: 'monospace'
                                            }}
                                        >
                                            {data.title}
                                        </Typography>
                                    </Card>
                                </Grow>

                            </Grid>
                        ))}
                    </Grid>
                </Container>

                <Box
                    component="footer"
                    sx={{
                        py: 2,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: '#272727',
                        width: '100%', // Full-width footer
                    }}
                >
                    <ConnectFooter />
                </Box>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={handleClose}
                sx={{
                    backgroundColor: 'rgba(18, 18, 18, 0.8)'
                }}
                PaperProps={{
                    style: {
                        borderRadius: 10,
                        background: '#272727'
                    }
                }}
            >
                <DialogTitle>
                    <Typography
                        sx={{
                            fontFamily: 'monospace',
                            fontSize: { xl: 40, lg: 35, md: 30, sm: 25, xs: 20 },
                            background: '#121212',
                            borderRadius: 5,
                            padding: 2
                        }}
                    >
                        {currProject !== null ? projects[currProject].title : ""}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography
                            sx={{
                                fontSize: { xl: 25, lg: 25, md: 25, sm: 25, xs: 20 },
                                color: '#ffffff'
                            }}
                        >
                            Project Description:
                        </Typography>
                        <br />
                        <Box
                            sx={{
                                background: '#121212',
                                borderRadius: 5,
                                padding: 2,
                            }}
                        >
                            {currProject !== null && projects[currProject].description.map((data, index) => (
                                <Typography
                                    sx={{
                                        fontSize: { xl: 18, lg: 18, md: 18, sm: 18, xs: 15 },
                                        color: '#ffffff',
                                        mb: 2
                                    }}
                                >
                                    {data}
                                </Typography>
                            ))}


                        </Box>
                        <br />
                        <Typography
                            sx={{
                                fontSize: { xl: 25, lg: 25, md: 25, sm: 25, xs: 20 },
                                color: '#ffffff'
                            }}
                        >
                            Responsibilities and Roles:
                        </Typography>
                        <br />
                        <Box
                            sx={{
                                background: '#121212',
                                borderRadius: 5,
                                padding: 2,
                            }}
                        >
                            {currProject !== null && projects[currProject].responsibilities.map((data, index) => (
                                <Typography
                                    sx={{
                                        fontSize: { xl: 18, lg: 18, md: 18, sm: 18, xs: 15 },
                                        color: '#ffffff',
                                        mb: 2
                                    }}
                                >
                                    {data}
                                </Typography>
                            ))}


                        </Box>
                        <br />
                        <Typography
                            sx={{
                                fontSize: { xl: 25, lg: 25, md: 25, sm: 25, xs: 20 },
                                color: '#ffffff'
                            }}
                        >
                            Tech Stack:
                        </Typography>
                        <br />
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {currProject !== null && projects[currProject].tech.map((data, index) => (
                                <Typography
                                    sx={{
                                        fontSize: { xl: 18, lg: 18, md: 18, sm: 18, xs: 15 },
                                        color: '#ffffff',
                                        background: '#121212',
                                        borderRadius: 4,
                                        padding: 1,
                                        mr: 1,
                                        mb: 1
                                    }}
                                >
                                    {data}
                                </Typography>
                            ))}
                        </Box>

                        <Box sx={{ mt: 4, flexWrap: 'wrap' }}>
                            {currProject !== null && projects[currProject].links.map((data, index) => (
                                <>
                                    <Link
                                        href={data}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            fontSize: { xl: 18, lg: 18, md: 18, sm: 18, xs: 15 },
                                            wordBreak: 'break-all', // Ensures that long words/links break and wrap
                                            overflowWrap: 'break-word', // Alternative way to handle wrapping
                                          }}
                                    >
                                        {data}
                                    </Link>
                                    <br />
                                </>
                            ))}
                        </Box>

                        {currProject !== null && projects[currProject].pictures.length !== 0 &&
                            <Container
                                sx={{
                                    mt: 4
                                }}
                            >
                               
                                <Grid
                                    container
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{
                                        //backgroundColor:'rgba(18, 18, 18, 1)',
                                        //padding: 4,
                                        borderRadius: 10,
                                    }}
                                >
                                    {currProject !== null && projects[currProject].pictures.map((data, index) => (
                                    <Grid 
                                        size = {{
                                            xl:6,
                                            lg:6,
                                            md:12,
                                            sm:12
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                position: "relative",
                                                overflow: "hidden",
                                                borderRadius: 3,
                                                border: 2,
                                                borderColor: "#000000",
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                image={data}
                                                className="image"
                                            />
                                        </Card>
                                    </Grid>
                                    ))}
                                </Grid>

                            </Container>
                        }

                    </DialogContentText>
                    <Box
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >
                    </Box>
                </DialogContent>
                <DialogActions>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            backgroundColor: '#121212',
                            mr: 2,
                        }}
                    >
                        <CloseIcon
                            sx={{
                                fontSize: 25,
                            }}
                        />
                    </IconButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
