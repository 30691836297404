import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import NavBar from './components/NavBar';
import { CSSTransition, SwitchTransition } from 'react-transition-group'; // Import necessary components
import Slide from '@mui/material/Slide';
import './App.css'; // Import CSS for animations

function App() {
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const [page, setPage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handlePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <NavBar handlePage={handlePage} pageNum={page} />
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={page}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            timeout={1000} // Ensure timeout matches Slide
            classNames="page"
          >
            <div>
              {page === 0 && (
                <Slide
                  in={true}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <div>
                    <Home />
                  </div>
                </Slide>
              )}
              {page === 1 && (
                <Slide
                  in={true}
                  direction={page > 1 ? 'right' : 'left'}
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <div>
                    <About />
                  </div>
                </Slide>
              )}
              {page === 2 && (
                <Slide
                  in={true}
                  direction={page > 2 ? 'right' : 'left'}
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <div>
                    <Projects />
                  </div>
                </Slide>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </ThemeProvider>
    </>
  );
}

export default App;
