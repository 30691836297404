import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MobileStepper from '@mui/material/MobileStepper';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Slide from '@mui/material/Slide';
import ArticleIcon from '@mui/icons-material/Article';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Spotify } from 'react-spotify-embed';

function ConnectFooter() {
    return (
        <Container maxWidth="sm">
            <Typography variant="body1">Lets Connect!</Typography>
            <IconButton href={'https://github.com/kikijee'}><GitHubIcon sx={{ fontSize: 35 }} /></IconButton>
            <IconButton href={'https://www.linkedin.com/in/christian-manibusan-885005259/'}><LinkedInIcon sx={{ fontSize: 35 }} /></IconButton>
            <IconButton href={'https://www.instagram.com/christian_manibusan/?hl=en'}><InstagramIcon sx={{ fontSize: 35 }} /></IconButton>
        </Container>
    );
}

const PDFViewer = ({ onClose }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: { xs: '90vw', md: '70vw' },
                height: { xs: '80vh', md: '80vh' },
                backgroundColor: '#272727',
                borderRadius: 2,
                boxShadow: 24,
                padding: 2,
                outline: 'none',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    color: '#ffffff'
                }}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ mb: 2 }}>
                My Resume
            </Typography>
            <iframe
                src="Manibusan_Christian_CV.pdf"
                width="100%"
                height="100%"
                style={{
                    border: 'none',
                    borderRadius: '10px',
                }}
            />
        </Box>
    );
};

export default function Home() {
    const [currentCard, setCurrentCard] = useState(0);
    const [isFadingOut, setIsFadingOut] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    const cardData = [
        { title: 'Card 1', image: '/DSC02765 - Copy_Original.JPEG', description: 'Me!' },
        { title: 'Card 3', image: '/IMG_1113.JPEG', description: 'Big Bear (there were no bears)' },
        { title: 'Card 4', image: '/IMG_7096.JPEG', description: 'My dog Kiki' },
        { title: 'Card 5', image: '/IMG_9925.JPEG', description: 'Gym' },
        { title: 'Card 6', image: '/IMG_2980.JPEG', description: 'Kiki again' },
        { title: 'Card 7', image: '/IMG_2231.JPEG', description: 'Uh' },
    ];

    const handlePrev = () => {
        setIsFadingOut(true); // Start fading out
        setTimeout(() => {
            setCurrentCard((prevCard) => (prevCard === 0 ? cardData.length - 1 : prevCard - 1));
            setIsFadingOut(false); // Reset fade effect after the transition
        }, 300); // Short delay for the transition to complete
    };

    const handleNext = () => {
        setIsFadingOut(true); // Start fading out
        setTimeout(() => {
            setCurrentCard((prevCard) => (prevCard === cardData.length - 1 ? 0 : prevCard + 1));
            setIsFadingOut(false); // Reset fade effect after the transition
        }, 300); // Short delay for the transition to complete
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'flex-start',
                backgroundImage: 'url("/DSC02903.JPEG")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'scroll',
                backgroundPosition: 'top-center',
            }}
        >
            <CssBaseline />
            <Box sx={{
                mt: { xl: 20, xs: 15 },
                mb: 5,
                textAlign: 'left',
            }}>
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                        <Slide
                            in={true}
                            direction='down'
                            {...(true ? { timeout: 2000 } : {})}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Box sx={{ ml: { xs: 0, sm: 0, md: '23%', lg: '22%', xl: '30%' }, padding: { xl: 0, xs: 2 } }}>
                                <Card variant="outlined" sx={{ borderRadius: 10, backgroundColor: 'rgba(18, 18, 18, 0.9)' }}>
                                    <CardContent>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                background: 'linear-gradient(45deg, #545050 30%, #b2b2b2 90%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                fontSize: { xs: 40, xl: 60 },
                                                fontFamily: 'sans-serif',
                                            }}
                                        >
                                            Christian Manibusan
                                        </Typography>
                                        <Typography
                                            variant="h2"
                                            component="h1"
                                            gutterBottom
                                            sx={{
                                                background: 'linear-gradient(45deg, #9c40e8 30%, #3964e5 90%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                display: 'inline-block',
                                                fontSize: { xl: 50, xs: 30 },
                                                fontFamily: 'monospace',
                                            }}
                                        >
                                            Software Engineer
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            marginBottom={2}
                                            sx={{ fontSize: { xl: 25, xs: 20 } }}
                                        >
                                            {'Hello, and welcome! My name is Christian, an aspiring software engineer with a passion to create solutions for real world problems. Currently looking for new opportunities!'}
                                        </Typography>

                                        <Container sx={{ mb: 3 }}>
                                            <Grid container spacing={2}>
                                                <Grid item size={6}>
                                                    <CardMedia
                                                        component="img"
                                                        image={'favicon (4).ico'}
                                                        sx={{
                                                            width: { xl: 300, lg: 200, md: 150, xs: 100 },
                                                            height: { xl: 300, lg: 200, md: 150, xs: 100 },
                                                            imageRendering: 'pixelated', // Ensures sharp edges
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    size={6}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography
                                                        marginBottom={2}
                                                        sx={{
                                                            fontSize: { xl: 18, xs: 15 },
                                                            fontFamily: 'monospace',
                                                        }}
                                                    >
                                                        {'Built with ❤ ... (React and MUI)'}
                                                    </Typography>
                                                </Grid>

                                            </Grid>

                                        </Container>

                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                            <Fab
                                                variant="extended"
                                                sx={{ fontWeight: 700, backgroundColor: '#949494' }}
                                                onClick={handleOpen}
                                            >
                                                <ArticleIcon sx={{ mr: 1 }} />
                                                <Typography sx = {{fontFamily:'monospace', fontWeight:550}}>My Resume</Typography>
                                            </Fab>
                                        </Box>

                                        <Modal
                                            open={isModalOpen}
                                            onClose={handleClose}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <PDFViewer onClose={handleClose} />
                                        </Modal>

                                    </CardContent>
                                </Card>
                            </Box>
                        </Slide>


                    </Grid>

                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                        <Slide
                            in={true}
                            direction='up'
                            {...(true ? { timeout: 2000 } : {})}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    maxWidth: '100%',
                                    ml: { xl: 15, xs: 0 },
                                    mb: { xl: 0, xs: 10 },
                                    mt: { xl: 2, lg: 2, sm: 2, xs: 8 },
                                    mr: { xl: '30%', lg: '10%', md: '10%', sm: 0, xs: 0 },
                                    padding: { xl: 2, xs: 0 },
                                }}
                            >
                                <Box
                                    elevation={24}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        mb: 2,
                                        borderRadius: 6,
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        py: 5,
                                        px: 2,
                                    }}
                                >

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, position: 'relative' }}>
                                        <Card
                                            sx={{
                                                flex: 1,
                                                borderRadius: 7,
                                                position: 'relative',
                                                overflow: 'hidden',
                                                '& img': {
                                                    transition: 'opacity 0.3s ease-in-out', // Fade transition
                                                    opacity: isFadingOut ? 0 : 1, // Transition between images
                                                },
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                image={cardData[currentCard].image}
                                                alt={cardData[currentCard].title}
                                                sx={{
                                                    width: { xl: 600, lg: 420, md: 350, sm: 300, xs: 290 },
                                                    height: { xl: 600, lg: 420, md: 350, sm: 300, xs: 320 },
                                                }}
                                            />
                                        </Card>
                                    </Box>


                                    <MobileStepper
                                        variant="dots"
                                        steps={cardData.length}
                                        position="static"
                                        activeStep={currentCard}
                                        sx={{
                                            width: { xl: '20%', lg: '30%', md: '30%', xs: '35%' },
                                            borderRadius: 10,
                                            justifyContent: 'center',
                                            '& .MuiMobileStepper-dotActive': {
                                                backgroundColor: '#ffffff',
                                            },
                                            backgroundColor: 'rgba(18, 18, 18, 1)',
                                        }}
                                        nextButton={
                                            <Box sx={{ ml: 3 }}>
                                                <IconButton onClick={handleNext}>
                                                    <ArrowForwardIosIcon sx={{ fontSize: { xs: 15 }, color: '#000000' }} />
                                                </IconButton>
                                            </Box>
                                        }
                                        backButton={
                                            <Box sx={{ mr: 3 }}>
                                                <IconButton onClick={handlePrev}>
                                                    <ArrowBackIosIcon sx={{ fontSize: { xs: 15 }, color: '#000000' }} />
                                                </IconButton>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Box>
                        </Slide>
                    </Grid>
                </Grid>
            </Box>

            <Box
                component="footer"
                sx={{
                    py: 2,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: '#272727',
                    width: '100%',
                }}
            >
                <ConnectFooter />
            </Box>
        </Box>
    );
}
