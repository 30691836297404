import * as React from 'react';
import { Box, CssBaseline, Typography, Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { GitHub as GitHubIcon, LinkedIn as LinkedInIcon, Instagram as InstagramIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MobileStepper from '@mui/material/MobileStepper';
import CardMedia from '@mui/material/CardMedia';
import Slide from '@mui/material/Slide';

function ConnectFooter() {
    return (
        <Container maxWidth="sm">
            <Typography variant="body1">Lets Connect!</Typography>
            <IconButton href={'https://github.com/kikijee'}><GitHubIcon sx={{ fontSize: 35 }} /></IconButton>
            <IconButton href={'https://www.linkedin.com/in/christian-manibusan-885005259/'}><LinkedInIcon sx={{ fontSize: 35 }} /></IconButton>
            <IconButton href={'https://www.instagram.com/christian_manibusan/?hl=en'}><InstagramIcon sx={{ fontSize: 35 }} /></IconButton>
        </Container>
    );
}

export default function About() {
    const sectionData = [
        {
            title: 'Background',
            description: `I am a recent graduate from California State University San Marcos with a Bachelor's Degree in Computer Science. 
            Throughout my academic and professional journey, I have honed my skills in Software Engineering through coursework and various personal projects. 
            I am always keen to learn and improve, and I believe that my grasp of the fundamentals has allowed me to pick up new technologies efficiently. 
            I recognize that there's always more to learn, and I'm excited about the opportunity to continue growing!`,
            pictures: ['07D88167-5429-4F53-95D8-B77B13F4F9E6.JPG','IMG_4007.JPEG','IMG_2296.JPEG','IMG_2406.JPEG'],
            c1: '#f2791d',
            c2: '#f7c49e',
        },
        {
            title: 'Goals',
            description: `My current goal is to kick-start my career as a Software Engineer and work with a passionate and driven team that shares a 
            strong mission. I'm excited about the opportunity to contribute to impactful projects where I can continue learning and growing, both personally 
            and professionally. I thrive in collaborative environments and am eager to work with like-minded individuals who are equally committed to pushing 
            boundaries and delivering innovative and scalable solutions.`,
            pictures: [],
            c1: '#f2791d',
            c2: '#f7c49e',
        },
        {
            title: 'Skills',
            description: `I have developed a robust foundation in programming languages such as Java, Python, C++, JavaScript, GoLang, HTML, CSS. A Diverse set of tools, 
            frameworks, and databases relevant to full-stack development, including Spring, Hardhat.js, React.js, Express.js, gRPC, RabbitMQ, PostgreSQL, MySQL, Firebase, 
            MongoDB. Deployment services and tools, Docker, Kubernetes, Cloudflare, AWS services, among others detailed in my resume and projects section.`,
            pictures: [],
            c1: '#f2791d',
            c2: '#f7c49e',
        },
        {
            title: 'Hobbies/etc.',
            description: `Outside of programming, I find myself outdoors most of the time. Some of my hobbies include Skateboarding, Bouldering, Running, Gyming, 
            Hiking, Playing Guitar, and probably a couple of other ones that I missed. From time to time I play video games, CS2 or Rust being my main choices 
            (I am horrible at them). Favorite Artists currently are Julie, Loathe, The Marías, Kublai Khan TX, and a whole lot more. My all time favorite movies 
            are Kung Fu Hustle, Yakuza Vampire, and Battle Royal.`,
            pictures: ['IMG_6159.JPEG','IMG_5420.PNG','IMG_2106.JPEG','09EC879F-96A4-4516-B505-0590E870480C.JPEG','DSC02923.JPEG','IMG_0005.JPG'],
            c1: '#f2791d',
            c2: '#f7c49e',
        },
    ];

    const [currentCardBackground, setCurrentCardBackground] = React.useState(0);
    const [currentCardHobbies, setCurrentCardHobbies] = React.useState(0);

    const [isFadingOutBackground, setIsFadingOutBackground] = React.useState(false);
    const [isFadingOutHobbies, setIsFadingOutHobbies] = React.useState(false);

    const handlePrev = (i) => {
        switch(i){
            case 0:
                setIsFadingOutBackground(true);
                setTimeout(()=>{
                    setCurrentCardBackground((prevCard) => (prevCard === 0 ? sectionData[i].pictures.length - 1 : prevCard - 1));
                    setIsFadingOutBackground(false);
                }, 300);
            case 1: 
                break;
            case 2:
                break;
            case 3:
                setIsFadingOutHobbies(true);
                setTimeout(()=>{
                    setCurrentCardHobbies((prevCard) => (prevCard === 0 ? sectionData[i].pictures.length - 1 : prevCard - 1));
                    setIsFadingOutHobbies(false);
                }, 300);
            default:
                break;
        }
    };

    const handleNext = (i) => {
        switch(i){
            case 0:
                setIsFadingOutBackground(true);
                setTimeout(()=>{
                    setCurrentCardBackground((prevCard) => (prevCard === sectionData[i].pictures.length - 1 ? 0 : prevCard + 1));
                    setIsFadingOutBackground(false);
                }, 300)
                
            case 1:
                break;
            case 2:
                break;
            case 3:
                setIsFadingOutHobbies(true);
                setTimeout(()=>{
                    setCurrentCardHobbies((prevCard) => (prevCard === sectionData[i].pictures.length - 1 ? 0 : prevCard + 1));
                    setIsFadingOutHobbies(false);
                }, 300)
                
            default:
                break;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                backgroundImage: 'url("image (1).jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'scroll',
                backgroundPosition: 'top-center',
            }}
        >
            <CssBaseline />

            <Container sx={{ mt: 20, mb: 10 }}>
                <Grid container spacing={8} justifyContent="center">
                    {sectionData.map((section, index) => (
                        <Grid
                            size={{xl:12,lg:12,md:9}}
                            key={index}
                            sx={{
                                ml: {
                                    xl: index % 2 ? 30 : 0,
                                    lg: index % 2 ? 30 : 0,
                                },
                                mr: {
                                    xl: index % 2 ? 0 : 30,
                                    lg: index % 2 ? 0 : 30,
                                }
                            }}
                        >
                            <Slide 
                                in={true}
                                direction={index % 2 ? "left" : "right"}
                                {...(true ? { timeout: 1000 } : {})}
                                mountOnEnter
                                unmountOnExit
                            >
                            <Card
                                variant="outlined"
                                sx={{
                                    borderRadius: 10,
                                    backgroundColor: 'rgba(18, 18, 18, 0.9)',
                                    padding: 2,
                                    display: {xl: 'flex', lg: 'flex', md: 'flex', sm:'flex', xs: 'flex-start'} // Add flexbox layout for horizontal alignment
                                }}
                            >
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            background: `linear-gradient(45deg, ${section.c1} 30%, ${section.c2} 90%)`,
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            display: 'inline-block',
                                            fontFamily: 'monospace',
                                            mb:1
                                        }}
                                    >
                                        {section.title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ fontSize: { xs: 14, md: 16, lg: 18, xl: 20 } }}
                                    >
                                        {section.description}
                                    </Typography>
                                </CardContent>

                                {/* White Card to the Right */}
                                { section.pictures.length !== 0 &&
                                <Box
                                    elevation={24}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column', // Stack the card and stepper vertically
                                        alignItems: 'center',
                                        borderRadius: 5,
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        paddingTop: 2,
                                        px:2,
                                        paddingBottom: {xs: 1},
                                        height:'80%',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <Card 
                                            sx={{ 
                                                flex: 1, 
                                                borderRadius: 5,
                                                position: 'relative',
                                                overflow: 'hidden',
                                                '& img': {
                                                    transition: 'opacity 0.3s ease-in-out', // Fade transition
                                                    opacity: index === 0 ? isFadingOutBackground ? 0 : 1 : isFadingOutHobbies ? 0 : 1, // Transition between images
                                                },
                                            }} 
                                        >
                                            <CardMedia
                                                component="img"
                                                image={index === 0 ? section.pictures[currentCardBackground] : index === 3 ? section.pictures[currentCardHobbies] : ''}
                                                sx={{ 
                                                    width: { 
                                                        xl: 300, lg: 275, sm:280, xs: 290 
                                                    }, 
                                                    height: { 
                                                        xl: 320, lg: 300, xs: 290 
                                                    },
                                                    objectFit: {xs:'contain'}, // Ensures the image is contained within the CardMedia box
                                                    backgroundColor: {xs:'black'}, // Sets the background color to black
                                                }}
                                            />
                                        </Card>
                                    </Box>

                                    {/* Move MobileStepper here and center it */}
                                    <MobileStepper
                                        variant="dots"
                                        steps={section.pictures.length}
                                        position="static"
                                        activeStep={index === 0 ? currentCardBackground : index === 3 ? currentCardHobbies : 0}
                                        sx={{
                                            width: {
                                                xl: '30%',
                                                lg:'30%',
                                                sm: '30%',
                                                xs: '28%',
                                            },
                                            height:{
                                                xl: '10%',
                                                xs: '10%',
                                            },
                                            mt:{
                                                xl: 2,
                                                xs: 2,
                                            },
                                            borderRadius: 5,
                                            justifyContent: 'center',
                                            '& .MuiMobileStepper-dotActive': {
                                                backgroundColor: "#ffffff",
                                            },
                                            backgroundColor: 'rgba(18, 18, 18, 1)'
                                        }} // Center stepper below the card
                                        nextButton={
                                            <Box 
                                                sx={{ 
                                                    ml: 3 
                                                }}
                                            > 
                                                <IconButton 
                                                    onClick={()=>{
                                                        handleNext(index);
                                                    }}
                                                >
                                                    <ArrowForwardIosIcon sx={{ fontSize: {xs:15},color: '#000000' }} />
                                                </IconButton>
                                            </Box>
                                        }
                                        backButton={
                                            <Box 
                                                sx={{ 
                                                    mr: 3
                                                }}
                                            > 
                                                <IconButton
                                                    onClick={()=>{
                                                        handlePrev(index);
                                                    }}
                                                >
                                                    <ArrowBackIosIcon sx={{ fontSize: {xs:15},color: '#000000' }} />
                                                </IconButton>
                                            </Box>
                                        }
                                    />
                                </Box>
                                }
                            </Card>
                            </Slide>
                        </Grid>
                    ))}
                </Grid>
            </Container>


            <Box
                component="footer"
                sx={{
                    py: 2,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: '#272727',
                    width: '100%',
                }}
            >
                <ConnectFooter />
            </Box>
        </Box>
    );
}
