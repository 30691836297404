import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

const pages = ['Home', 'About Me', 'Projects'];

function NavBar(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      threshold: 5,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children ?? <div />}
      </Slide>
    );
  }


  return (
    <HideOnScroll {...props}>
    <AppBar
      position="fixed"
      sx={{
        mt: 5,
        mx: { xs: 2, lg: 20, xl: 60 }, // Adds margin to the left and right
        width: { xs: `calc(100% - 32px)`, lg: `calc(100% - 320px)`,xl: `calc(100% - 960px)` }, // Ensures it fits the container minus the horizontal margins
        borderRadius: 10, // Adjusts the roundness of the corners
        borderBottom: '1px solid',
        borderColor: 'divider',
        boxShadow: 0,
      }}
    >

      <Toolbar variant="regular">
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
          }}
        >



          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, i) => (
                <MenuItem 
                  key={page} 
                  onClick={()=>{
                    handleCloseNavMenu()
                    props.handlePage(i)
                  }}
                >
                  <Typography sx={{ textAlign: 'center', fontFamily:'monospace' }}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          <Box sx={{ display: 'flex' , justifyContent: 'center', flexGrow: 1 }}>
            {pages.map((page, i) => (
              <Button
                key={page}
                onClick={()=>{
                  handleCloseNavMenu()
                  props.handlePage(i)
                }}
                disabled = {i === props.pageNum}
                sx={{ my: 2, color: 'white', display: 'block', mx: {xl:3,lg:3,md:3,sm:3,xs:1} }}
              >
                <Typography
                  sx = {{
                    fontFamily: 'monospace'
                  }}
                >
                  {page}
                </Typography>
              </Button>
            ))}
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
    </HideOnScroll>
  );
}
export default NavBar;